<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-4">
        You prepare a
        <stemble-latex content="$0.20\,\text{M}$" />
        solution of an unknown monoprotic acid. Testing the
        <stemble-latex content="$\text{pH}$" />
        of the solution, you find that it is
        <number-value :value="pH" />
        .
      </p>

      <p class="mb-2">
        a) What is the
        <stemble-latex content="$\text{K}_\text{a}$" />
        value of the unknown acid?
      </p>

      <calculation-input
        v-model="inputs.Ka"
        class="mb-5"
        prepend-text="$\text{K}_\text{a}$"
        :disabled="!allowEditing"
      />

      <p class="mb-3">
        b) If you had a
        <number-value :value="concA" unit="\text{M}" />
        solution of the
        <span class="text-bold">conjugate base</span> of the unknown acid, prepare an ICE table for
        the dissolution of this base. Use
        <stemble-latex content="$x$" />
        for any relative changes. Be sure to indicate the direction of change, i.e. +/-. Be sure to
        use the proper number of significant figures. Any zeroes should be denoted as 0. The
        equilibrium row should still contain the
        <stemble-latex content="$x$" />
        variable.
      </p>

      <v-simple-table class="mb-5" style="max-width: 550px" dense>
        <thead>
          <tr>
            <th style="vertical-align: middle; text-align: center; height: 60px" />
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <chemical-latex content="A-(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <chemical-latex content="H2O(l)" />
            </th>
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <chemical-latex content="<=>" />
            </th>
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <chemical-latex content="HA(aq)" />
            </th>
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <stemble-latex content="$+$" />
            </th>
            <th style="vertical-align: middle; text-align: center; height: 60px">
              <chemical-latex content="OH^-(aq)" />
            </th>
          </tr>

          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{I}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialHA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.initialOH" class="centered-input" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{C}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeHA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.changeOH" class="centered-input" />
            </td>
          </tr>
          <tr>
            <td style="vertical-align: middle; height: 60px; text-align: center">
              <stemble-latex content="$\textbf{E}$" />
            </td>
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <stemble-latex content="$-$" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumHA" class="centered-input" />
            </td>
            <td />
            <td style="vertical-align: middle; text-align: center">
              <ice-input v-model="inputs.equilibriumOH" class="centered-input" />
            </td>
          </tr>
        </thead>
      </v-simple-table>

      <p class="mb-2">
        c) Determine the value of
        <stemble-latex content="$x$" />
        for the solution that was described in part b).
      </p>

      <calculation-input
        v-model="inputs.valueOfx"
        class="mb-5"
        prepend-text="$x:$"
        append-text="$\text{M}$"
        :disabled="!allowEditing"
      />

      <p class="mb-2">
        d) Determine the
        <stemble-latex content="$\text{pH}$" />
        of the solution that was described in part b).
      </p>

      <calculation-input
        v-model="inputs.pHc"
        :prepend-text="'$\\text{pH}$'"
        :disabled="!allowEditing"
      />
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ChemicalLatex from '../displayers/ChemicalLatex';
import CalculationInput from '../inputs/CalculationInput';
import IceInput from '../inputs/IceInput';
import NumberValue from '../NumberValue';
import StembleLatex from '@/tasks/components/StembleLatex';

export default {
  name: 'Question178',
  components: {
    StembleLatex,
    ChemicalLatex,
    CalculationInput,
    NumberValue,
    IceInput,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        Ka: null,
        initialA: null,
        initialOH: null,
        initialHA: null,
        changeA: null,
        changeOH: null,
        changeHA: null,
        equilibriumA: null,
        equilibriumOH: null,
        equilibriumHA: null,
        valueOfx: null,
        pHc: null,
      },
    };
  },
  computed: {
    concA() {
      return this.taskState.getValueBySymbol('concA').content;
    },
    pH() {
      return this.taskState.getValueBySymbol('pH').content;
    },
  },
};
</script>
